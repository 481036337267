import React from 'react';
import './HomePage.styles.scss';

const HomePage = () => {
  return (
    <div className="homepage">
      <h1>Welcome to my Homepage</h1>
      <div className="directory-menu">
        <div className="menu-item">
          <div className="content">
            <div className="title">MANAGEMENT</div>
            <span className="subtitle">ENTER NOW</span>
          </div>
        </div>
        <div className="menu-item">
          <div className="content">
            <div className="title">FRONT END</div>
            <span className="subtitle">ENTER NOW</span>
          </div>
        </div>
        <div className="menu-item">
          <div className="content">
            <div className="title">BACK END</div>
            <span className="subtitle">ENTER NOW</span>
          </div>
        </div>
        <div className="menu-item">
          <div className="content">
            <div className="title">SYSTEM DESIGN</div>
            <span className="subtitle">ENTER NOW</span>
          </div>
        </div>
        <div className="menu-item">
          <div className="content">
            <div className="title">COACHING</div>
            <span className="subtitle">ENTER NOW</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
