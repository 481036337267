import React, { Component } from 'react';
import HomePage from '../components/InteractiveComponents/HomePage/HomePage';
import Sidebar from '../components/Sidebar';
import Menubar from '../components/Menubar';

export default class interactive extends Component {
  render() {
    return (
      <div>
        <Menubar />
        <Sidebar showTabs="false"></Sidebar>
        <HomePage />
      </div>
    );
  }
}
